const initPrint = function() {
    if($("#print-logos").length){
        $('.print-button').click(function(e){
            e.preventDefault();

            if($(this).attr("visible") == "true") {
                $(this).attr("visible", false);

                $('#print-logo-list').fadeOut();

                $(this).find('.fa-caret-up')
                    .addClass('fa-caret-down')
                    .removeClass('fa-caret-up');

                return;
            }

            $(this).attr("visible", "true");

            $(this).find('.fa-caret-down')
                .addClass('fa-caret-up')
                .removeClass('fa-caret-down');

            $('#print-logo-list').fadeIn();
        });

        $('#print-logo-list li a').click(function(e) {
            e.preventDefault();
            var printButton = $('.print-button');
            printButton.attr("visible", false);

            $('#print-logo-list').fadeOut();

            var newImgSrc = "/img/logos/" + $(this).data("logo");

            if($('.print-logo').attr("src") == newImgSrc) {
                window.print();
            }
            else {
                $('.print-logo').attr("src", newImgSrc).one("load",function(){
                    window.print();
                });
            }

            printButton.find('.fa-caret-up')
                .addClass('fa-caret-down')
                .removeClass('fa-caret-up');

        })
    }

    if($(".js-print").length){
        $('.js-print').click(function(e){
            e.preventDefault();
            window.print();
        })
    }
}

export {initPrint};
