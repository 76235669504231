const initTracking = function () {
    $(document).ready(function() {
        if($('.homepage-links').length) {
            $('.homepage-links a').click(function() {
                var linkText = $(this).text();

                if(typeof ga != "undefined") {
                    ga('send', 'event', 'Homepage', 'Link Click', linkText);
                }
            })
        }
    });
}

export default initTracking;
