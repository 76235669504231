const initAccessibility = function() {
    if( $('.accessibility').length > 0 )
    {
        $.ajax({
            url: "/cookies/get/name/font-size",
            success: function(data) {
                if( data < 84 ) data = 84;
                $('body').css({
                    fontSize : ( data ) + '%'
                });
            }
        });

        $('.accessibility').click( function(){

            $('body').css({
                fontSize : ( $(this).attr('rel' ) )
            });
            $.ajax({
                url: "/cookies/set/name/font-size/value/" + parseInt( $(this).attr('rel' ) )
            });
        });
    }
}

export {initAccessibility};
