import {alterDateFormat} from "./lib/Utils";
import initTracking from "./components/tracking";
import {initScreentime, setScreentimeHash} from "./components/screentime";
import {initBopToggles, initLapToggles} from "./components/toggles";
import {initPlacholder} from "./components/placeholder";
import {initContentAccordion} from "./components/content-accordion";
import {initCollapsable} from "./components/collapsable";
import {initDatePicker} from "./components/date-picker";
import {initPrint} from "./components/print";
import {initAccessibility} from "./components/accessibility";
import {initSearch} from "./components/search";
import {initMultiSelect} from "./components/multi-select";

window.setScreentimeHash = setScreentimeHash;

$(document).ready(function() {
    initTracking();
    initScreentime();
    initBopToggles();
    initLapToggles();
    initPlacholder();
    initContentAccordion();
    initCollapsable();
    initDatePicker();
    initPrint();
    initAccessibility();
    initSearch();
    initMultiSelect();

    if ($('#members').length) {
        $('option').on('click', function (event) {
            $('#members').html("");
            $('option:selected').each(function (ind, ele) {
                $("#members").append("<li>" + $(ele).text() + "</li>");
            });
        });
    }

    if(typeof CKEDITOR != "undefined") {
        CKEDITOR.dtd.$removeEmpty['i'] = false;
    }

    if ($('.changed-date').length) {
        alterDateFormat();
    }
});
