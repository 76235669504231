require('jquery-ui.1.11.1/ui/accordion');

import {scrollTo} from "../lib/Utils";

const initContentAccordion = function () {
    $(".cw-content-accordion").accordion({
        collapsible: true,
        active: 'none',
        heightStyle: "content",
        activate: function (event, ui) {
            if (!$.isEmptyObject(ui.newHeader.offset())) {
                $('html:not(:animated), body:not(:animated)').animate({
                        scrollTop: ui.newHeader.offset().top
                    }, 'slow'
                );
            }
        }
    });

    if($('.cw-contents').length) {
        $('.cw-contents li > a').click(function(e) {
            var id = $(this).attr('href');

            if(typeof id === 'undefined') return;

            e.preventDefault();

            scrollTo(id, 50);
        });
    }

    if($('img.arrow-up').length) {
        $('img.arrow-up').parent().click(function(e) {
            var id = $(this).attr('href');

            if(typeof id === 'undefined') return;

            e.preventDefault();

            scrollTo(id, 50);
        });
    }

    $("html, body").bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(){
        $('html, body').stop();
    });

    //$('#trading-issues > h3').append('<span>See details <i class="fa fa-caret-down"></i></span> <span class="tradingToggleUp">Hide details <i class="fa fa-caret-up"></i></span>');
    // $('#trading-issues > div').css('display', 'none');
    //
    // $('h3').click(function() {
    //     $(this).find('span').toggle();
    //     $(this).next('div').toggle();
    // });
}

export {initContentAccordion}
