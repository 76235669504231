const initSearch = function() {
    $("#search_more").click(function(){
        if($(this).hasClass("active")){
            $(".searchMore").slideUp("fast");
            $(this).removeClass("active");
            $(this).html("show me more options");
        }else{
            $(".searchMore").slideDown("fast");
            $(this).addClass("active");
            $(this).html("hide advanced options");
        }
        return false;
    });
}

export {initSearch};
