require('../lib/MultipleSelect');

const initMultiSelect = function() {
    let resourceSelect = $(".add-resource-select");

    if(resourceSelect.length){
        resourceSelect.multipleSelect({
            filter: true,
            width: '100%'
        });
    }
}

export {initMultiSelect};
