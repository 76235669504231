const initCollapsable = function() {
    $('.collapsable').each(function(idx, ele) {
        if($(ele).data('collapsed') === true) {
            var target = $(ele).find('.content');
            target.hide();
            $(ele).find('.title-bar').append('<a href="#" class="ui-collapse-button ss_sprite ss_bullet_arrow_down"></a>');
        } else {
            $(ele).find('.title-bar').append('<a href="#" class="ui-collapse-button ss_sprite ss_bullet_arrow_up"></a>');
        }

        $(ele).find('.ui-collapse-button, .title-bar').click(function(evt) {

            var collapsable = $(this).parents('.collapsable');
            var content = collapsable.find('.content');
            var icon = collapsable.find('.ui-collapse-button');

            if($(content).css('display') == 'none') {
                content.show();
                icon.removeClass('ss_bullet_arrow_down').addClass('ss_bullet_arrow_up');
            } else {
                content.hide();
                icon.removeClass('ss_bullet_arrow_up').addClass('ss_bullet_arrow_down');
            }

            evt.preventDefault();
            evt.stopPropagation();
        });
    });
}

export {initCollapsable};
