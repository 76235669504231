function scrollTo(target, duration) {
    var name = target.substring(1);
    var ele = $(target).length ? $(target) : $('[name="'+name+'"]');

    $('html,body').animate({
        scrollTop: ele.offset().top
    },duration);
}

function decodeEntities(s){
    return $('<div />').html(s).text();
}

function alterDateFormat() {
    let element = $('tbody').children('tr').first().children('td');
    let date = $('tbody').children('tr').first().children('td')[0].innerText;
    let parts = date.split(' ');
    parts = parts.map(function(e) {
        if (e.match(/([0-9])/)) {
            return e.replace(/(st)|(nd)|(rd)|(th)/, '');
        } else {
            return e;
        }
    });
    date = parts.join(' ');
    element.text(date);
}


export {scrollTo, decodeEntities, alterDateFormat}
