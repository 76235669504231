require('jquery-ui.1.11.1/ui/datepicker');

const initDatePicker = function() {
    if( $( ".datepicker" ).length > 0){
        $(".datepicker").attr("autocomplete", "off");
        $( ".datepicker" ).datepicker({
            showOn: "focus",
            buttonImage: "/themes/common/img/icons/calendar_icon.png",
            buttonImageOnly: true,
            dateFormat: "yy-mm-dd"
        });
    }
}

export {initDatePicker};
