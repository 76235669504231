let screentimeHash = null;

const initScreentime =  function initScreentime() {
    if(typeof screentimeHash !== 'undefined' && screentimeHash !== null) {
        let start = Date.now();
        let unloading = false;

        window.onbeforeunload = function(e) {
            if(unloading == false) {
                $.ajax({
                    type: "POST",
                    url: '/api/updatescreentime/',
                    data: {
                        timespent : Math.floor((Date.now()-start)/1000),
                        hash: screentimeHash
                    },
                    async: false
                });

                unloading = true;
            }

            return undefined;
        }
    }
}

const setScreentimeHash = function (hash) {
    screentimeHash = hash;
}

export {initScreentime, setScreentimeHash}
